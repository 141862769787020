import * as _foreach2 from "foreach";

var _foreach = "default" in _foreach2 ? _foreach2.default : _foreach2;

import * as _availableTypedArrays2 from "available-typed-arrays";

var _availableTypedArrays = "default" in _availableTypedArrays2 ? _availableTypedArrays2.default : _availableTypedArrays2;

import * as _callBound2 from "call-bind/callBound";

var _callBound = "default" in _callBound2 ? _callBound2.default : _callBound2;

import * as _shams2 from "has-tostringtag/shams";

var _shams = "default" in _shams2 ? _shams2.default : _shams2;

import * as _getOwnPropertyDescriptor2 from "es-abstract/helpers/getOwnPropertyDescriptor";

var _getOwnPropertyDescriptor = "default" in _getOwnPropertyDescriptor2 ? _getOwnPropertyDescriptor2.default : _getOwnPropertyDescriptor2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var forEach = _foreach;
var availableTypedArrays = _availableTypedArrays;
var callBound = _callBound;
var $toString = callBound("Object.prototype.toString");

var hasToStringTag = _shams();

var g = typeof globalThis === "undefined" ? _global : globalThis;
var typedArrays = availableTypedArrays();

var $indexOf = callBound("Array.prototype.indexOf", true) || function indexOf(array, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i] === value) {
      return i;
    }
  }

  return -1;
};

var $slice = callBound("String.prototype.slice");
var toStrTags = {};
var gOPD = _getOwnPropertyDescriptor;
var getPrototypeOf = Object.getPrototypeOf; // require('getprototypeof');

if (hasToStringTag && gOPD && getPrototypeOf) {
  forEach(typedArrays, function (typedArray) {
    var arr = new g[typedArray]();

    if (Symbol.toStringTag in arr) {
      var proto = getPrototypeOf(arr);
      var descriptor = gOPD(proto, Symbol.toStringTag);

      if (!descriptor) {
        var superProto = getPrototypeOf(proto);
        descriptor = gOPD(superProto, Symbol.toStringTag);
      }

      toStrTags[typedArray] = descriptor.get;
    }
  });
}

var tryTypedArrays = function tryAllTypedArrays(value) {
  var anyTrue = false;
  forEach(toStrTags, function (getter, typedArray) {
    if (!anyTrue) {
      try {
        anyTrue = getter.call(value) === typedArray;
      } catch (e) {
        /**/
      }
    }
  });
  return anyTrue;
};

exports = function isTypedArray(value) {
  if (!value || typeof value !== "object") {
    return false;
  }

  if (!hasToStringTag || !(Symbol.toStringTag in value)) {
    var tag = $slice($toString(value), 8, -1);
    return $indexOf(typedArrays, tag) > -1;
  }

  if (!gOPD) {
    return false;
  }

  return tryTypedArrays(value);
};

export default exports;